.center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;

	& > div {
		width: 100%;
		max-width: 360px;
	}
}
