.whitebox {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 8px;
	padding: 16px;
	width: calc(100% - 32px);

	form {
		width: 100%;
	}
}
