@import "../../styles/colors";

.box {
	display: flex;
}

.link {
	font-size: 15pt;
	padding: 15px;
	color: #e4e4e4;
	white-space: nowrap;

	&:hover {
		color: white;
	}

	@media screen and (max-width: 600px) {
		font-size: 13pt;
		padding: 15px 5px;
	}
}

.flex {
	display: flex;
	align-items: center;
}
