.white {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;

	margin-top: 20px;
	color: white;
	font-weight: 600;
}

.icon {
	margin: 0 10px;
}
