html,
body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;

	::-webkit-scrollbar {
		width: 16px;
	}
	::-webkit-scrollbar-track {
		background: black;
	}
	::-webkit-scrollbar-thumb {
		background: #10181b;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #3a4447;
	}
	::-webkit-scrollbar-corner {
		background: #10181b;
	}
}
