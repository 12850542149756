.size {
	width: 100%;
	height: calc(100vh - 70px);
}

.background {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: -1000;
}

.gradient {
	position: absolute;
	bottom: 0 px;
	left: 0 px;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, #00000044, #000000);
	opacity: 1;
}
